import React, { useEffect } from "react";

const RedirectDeepLink = () => {
  useEffect(() => {
    // Rediriger l'utilisateur dès que le composant est monté
    try {
      console.log("log");
      window.location.href = "bringmesn://success";
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      <p>Redirection en cours...</p>
    </div>
  );
};

export default RedirectDeepLink;
